@media only screen and (max-width: 40em) {

  @media (orientation: landscape){
  
  }

  /*iPhone < 5:*/
  @media (device-aspect-ratio: 2/3) {}

  /*iPhone 5:*/
  @media (device-aspect-ratio: 40/71) {}
}