$site-red: #c91919;
$site-blue: #00638e;
$white: #fff;
$black: #000;
$grey-link: #c3c3c3;

html, body {
  height: 100%;

  ::-webkit-outer-spin-button,
  ::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
}

*:focus {
  outline: none;
}

body {
  font-size: 16px;
  font-family: $source-sans-pro, Helvetica,Arial,sans-serif;
  color: #000;
  -ms-overflow-style: scrollbar;
  // @include transform(scale(1));
  //background: #484848;
}


p, span, a, a:hover, a:visited {
  color: $black;
}

a{
	text-decoration: none;
}

nav{
  width: 100%;

  &.shrink{

  	.logo{
  		&:before,
  		&:after{
  			top: 0px;
		    height: 92px;
  		}
  	}
  }
}

.nav-bar{
	font-family: $source-sans-pro;
	background: $site-blue;
	color: $white;

	.social-media{
		margin: 0;
    padding: 5px 0;
    display: flex;
    align-items: center;

		li{
			display: inline-block;
			overflow: hidden;

			a{
				@include transition(color 0.3s linear);
				display: block;
				padding: 0 5px;
				color: $white;

				&:hover{
					color: $black;
				}

				span{
					display: none;
				}
			}
		}
	}
}

header{
	min-height: 48px;
  z-index: 100;
}

.title-bar{
	@include transition(background 0.3s linear);
	background: $site-red;
	padding: 0;
	@include dropshadow(0 2px 6px 0 rgba(0, 0, 0, 0.2));

	.logo{
		position: relative;
		display: block;

		h1{
			text-indent: -999px;
			overflow: hidden;
			//display: none;
		}

		&:before,
		&:after{
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			top: -21px;
	    display: flex;
	    height: 142px;
		}

		// &:before{
		// 	background: $black;
		// }

		&:after{
			@include transition(height 0.3s linear);
			background: transparent url(../img/galaxy-tv-logo.png) no-repeat;
			@include background-size(contain);
			background-position: center;
		}
	}

	.fa-search{
	  padding: 1.5rem 0;
	  font-size: 32px;
	}

	.live-tv{
		@include transition(all 0.3s linear);
		//margin: 1.5rem 0;
		padding: 5px 10px;
		display: inline-block;
		background: $black;
		color: $grey-link;
		//border: 1px solid $white;
		@include font-small;
		text-transform: uppercase;
		font-weight: 700;
    position: relative;
    top: -5px;

		&:hover{
			color: $white;
		}
	}

	.menu{
		a{
			span{
				@include font-small;
				text-transform: uppercase;
				font-weight: 700;
				color: $white;
			}
		}

		>li > a{
			position: relative;
	    padding: 2rem 0.5rem;

			&:before{
				@include transition(width 0.3s $easeInQuad);
				content: '';
				height: 2px;
				width: 0;
				margin: 0 auto;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				background: $black;
			}

			&:hover{
				&:before{
					width: 100%;
				}
			}

			&:after{
				border-color: $white transparent transparent !important;
			}
		}

		.active{
			a{
				background: none;

				&:before{
					width: 100%;
				}
			}
		}

		.submenu{
			list-style: none;
			padding: 0;
			margin: 0;
			border:  none;
			//background: $black;
			@include dropshadow(0 2px 6px 0 rgba(0, 0, 0, 0.2));

			li{
				padding: 5px 10px;

				a span{
					color: $black;
				}
			}
		}
	}
}

.button{
	@include transition(background 0.3s linear);
	font-family: $source-sans-pro;

	label{
		color: inherit;
	}

  &.facebook{
    background: #3D5D96;
    color: $white;
    span,
    &:before{
      color: inherit;
    }

    &:hover{
    	background: #2d456f;
    }
  }

  &.twitter{
    background: #00aced;
    color: $white;
    span,
    &:before{
      color: inherit;
    }

    &:hover{
    	background: #057fad;
    }
  }

  &.instagram{
    background: #fbad50;
    color: $black;
    span,
    &:before{
      color: inherit;
    }

    &:hover{
    	background: #00aced;
    }
  }

  &.google{
    background: #eb4026;
    color: $white;
    span,
    &:before{
      color: inherit;
    }

    &:hover{
    	background: #ce3b25;
    }
  }

  &.youtube{
    background: #bb0000;
    color: $white;
    span,
    &:before{
      color: inherit;
    }

    &:hover{
    	background: #a20202;
    }
  }

  label{
  	cursor: pointer;
  }
}

#ad-container{
	//height: 150px;
	padding: 50px 0 0;
	// position: fixed;
	// left: 0;
	// right: 0;
	// top: 0;
	// background: $white;

	.row{
    //border: 1px solid $grey-link;
    max-width: 972px;
    .column{
    	padding: 0;

    	.adsbygoogle{
    		background: $black;
    	}
    }
	}
}

main{
	//padding: 194px 0 0;
	padding: 44px 0 0;
	@include font-medium();
	font-family: $source-sans-pro;

	h2{
		@include font-xx-large();
	}

	p, strong, a, span, div{
		font-size: inherit !important;
		font-family: inherit !important;
	}

	p{
		margin: 0 0 10px !important;
	}
}

footer{
  background: $site-red;
  padding: 50px 0;
  color: $white;

  h4{
  	font-weight: 600;
  }

  a,
  a:visited,
  span,
  p{
  	color: inherit;
  }

  a{
  	@include transition(color 0.3s linear);
  	&:hover{
			color: $black;
		}
  }

  .social-media{
  	li{
  		padding: 0 0 10px;
  	}
  }
}

.copyright{
	@include font-small();
	font-weight: 600;
	background: $site-blue;
	color: $white;
	padding: 10px 0; 
}

.breadcrumbs{
	li{
		&:first-child{
			display: none;
		}

		color: $grey-link;
		text-transform: normal;
		text-transform: none;
		text-decoration: none;
		font-family: $source-sans-pro;

		&:last-child{
			color: $black;
		}

		a{
			@include transition(color 0.3s linear);
			color: inherit;


			&:hover{
				color: $black;
			}
		}
	}
}

.prev-next-post{
	padding: 50px 0 0;

	h5{
		font-weight: 600;
		color: $grey-link;
		@include font-medium();
	}
}

.article-image-block{
	//margin: 0 0 40px;

	h4,
	h5{
		font-family: $source-sans-pro;
		@include font-medium();
		display: inline-block;
		margin: 0;
	}

	h4{		
		background: $grey-link;
		padding: 5px 10px;
	}

	hr{
		border: none;
		border-top: 2px solid $grey-link;
		margin: 0;
		padding: 0 0 20px;
	}

	&.news,
	&.foreign-news,
	&.local-news,
	&.entertainment,
	&.politics{
		h4{
			background: $site-red;
			color: $white;
		}

		hr{
			border-color: $site-red;
		}
	}

	&.entertainment,
	&.sports,
	&.business{
		h4{
			background: $site-blue;
			color: $white;
		}

		hr{
			border-color: $site-blue;
		}
	}

	&.related-articles,
	&.latest-videos{
		h4{
			background: $black;
			color: $white;
		}

		hr{
			border-color: $black;
		}
	}

	.hover-content{
		display: block;
		position: relative;
	  @include transition(transform 0.3s linear);
	  @include transform(scale3d(1, 1, 1));

	  &:hover{
	    @include transform(scale3d(1.05, 1.05, 1.05));
	  }
	}

	.column{
		margin: 0 0 20px;
	}

	.category-title{
		.column{
			margin: 0;
		}
	}

	.title{

	}

	h3,
	h2{
		font-family: $source-sans-pro;
  	margin: 0;
  }

  h3{
  	@include font-medium();
  }

  h2{
		@include font-xx-large();
		font-weight: 700;
  }

  .active-video{
  	padding: 10px;
  	background: $site-red;
  	
  	span{
  		color: $white;
  		font-weight: 700;
  	}
  }

	&.single-block{
		h3{
			position: absolute;
	  	bottom: 0;
	  	right: 0;
	  	left: 0;
	  	padding: 20px;
	  	color: $white;

	  	&:before{
		  	content: '';
		  	position: absolute;
		  	top: 0;
		  	bottom: 0;
		  	right: 0;
		  	left: 0;
		  	background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
			  /* FF3.6+ */
			  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0.8)));
			  /* Chrome,Safari4+ */
			  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
			  /* Chrome10+,Safari5.1+ */
			  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
			  /* Opera 11.10+ */
			  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
			  /* IE10+ */
			  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
			  /* W3C */
			  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#b3000000', GradientType=0);
			  /* IE6-9 */
		  }

		  span{
		  	position: relative;
		  	color: inherit;
		  }
		}

		hr{
			padding: 0;
		}
	}

	.master-slider{
		.ms-caption{
			bottom: 0;
			right: 0;
	  	left: 0;
			h2{
		  	padding: 20px;
		  	color: $white;
				&:before{
			  	content: '';
			  	position: absolute;
			  	top: 0;
			  	bottom: 0;
			  	right: 0;
			  	left: 0;
			  	background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
				  /* FF3.6+ */
				  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0.8)));
				  /* Chrome,Safari4+ */
				  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
				  /* Chrome10+,Safari5.1+ */
				  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
				  /* Opera 11.10+ */
				  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
				  /* IE10+ */
				  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
				  /* W3C */
				  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#b3000000', GradientType=0);
				  /* IE6-9 */
			  }

			  span{
			  	color: inherit;
			  	position: relative;
			  }
			}
		}
	}
}

.hero-feature,
.homepage-feature{
	padding: 0 0 20px;
}

.hero-feature{

	.feature{
		img{
	  	height: 400px;
	  }
	}

	.feature-list{
		a{
			margin: 0 0 30px;

			&:last-child{
				margin: 0;
			}
		}
	}

	.feature,
	.feature-list{
		a{
			position: relative;
			display: block;

			h3,
			h2{
				font-family: $source-sans-pro;
		  	margin: 0;
		  }

		  h2{
		  	@include font-xx-large();
		  }

		  h3{
		  	@include font-medium();
		  }

			h2,
			h3{
				position: absolute;
		  	bottom: 0;
		  	right: 0;
		  	left: 0;
		  	padding: 20px;
		  	color: $white;

		  	&:before{
			  	content: '';
			  	position: absolute;
			  	top: 0;
			  	bottom: 0;
			  	right: 0;
			  	left: 0;
			  	background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
				  /* FF3.6+ */
				  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0.8)));
				  /* Chrome,Safari4+ */
				  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
				  /* Chrome10+,Safari5.1+ */
				  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
				  /* Opera 11.10+ */
				  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
				  /* IE10+ */
				  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
				  /* W3C */
				  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#b3000000', GradientType=0);
				  /* IE6-9 */
			  }

			  span{
			  	position: relative;
			  	color: inherit;
			  }
			}
		}
	}
}

.breaking-news{
	h3{
		background: $black;
		font-family: $source-sans-pro;
		@include font-medium();
  	margin: 0;
  	color: $white;
  	padding: 10px;
  	font-weight: 600;
  }

  &.top-stories{
  	ul{
	  	li{
	  		font-family: $source-sans-pro;
	  		border-bottom: 1px solid $grey-link;
	  		@include font-medium();
	  		padding: 5px 0;

	  		&:last-child{
	  			border: none;
	  		}

	  		.title{
	  			white-space: nowrap;
	        overflow: hidden;
	        text-overflow: ellipsis;
	  		}
	  	}
	  }
  }

  &.hp-hero{
  	h2{
  		font-family: $source-sans-pro;
  		@include font-xxx-large();
  		width: 90%;
  		margin: 0 auto;
  		font-weight: 600;
  		position: relative;
    	margin-top: -15px;
  	}
  }

	.title-bar{
  	background: $black;

  	.live-tv{
  		background: $site-red;
  	}

  	.menu > li > a:before{
  		background: $white;
  	}
  }
}

.no-breaking{
	.breaking-bar.top-head {
		border-bottom: none;
	}
}

.breaking-bar.top-head {
  background: $site-red;
  border-bottom: 3px solid #ff0000;
  .callout{
    border: none;
    background: none;
    margin: 0;

    h2{
    	font-family: $source-sans-pro;
    	color: $white;
    	margin: 0;
	    text-align: left;
	    @include font-medium();

	    &:before{
	    	content: 'BREAKING NEWS';
	    	display: block;
	    	font-weight: 700;
	    }

    	span{
    		color: inherit;
    		font-family: inherit;
    		@include font-large();
    	}
    }

    .close-button{
    	font-family: $source-sans-pro;
    	color: $white;
    	span{
    		color: inherit;
    		font-family: inherit;
    	}
    }

    img{
    	display: none;
    }
  }
}


.homepage-feature{
	.breaking-news{
	  //padding: 14px 0 0;
	}
}

.pagination{
	font-family: $source-sans-pro;

	a{
		background: transparent;

		&:hover{
			background: transparent;
		}
	}

	li{
		@include transition(background 0.3s linear);
	}

	li:hover,
	.current{
    background: #c91919;
    font-weight: 600;

    a{
    	color: #fff;
    }
	}
}

.ad300x250{
	padding: 0 0 20px;
}

.menu-search{
	padding: 0 30px;
	position: relative;

	&:before{
		content: '';
		display: none;
		z-index: 3;
	}

	&:before,
	.search-bar{
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 30px;
		width: 0;
	}

	.search-bar{
		@include transition(left 0.8s linear);
		left: 100%;
		width: auto;
		margin: auto;
		margin-left: 30px;
		height: 44px;
		background: $white;
		z-index: 4;
		overflow: hidden;

		.sisea-search-form{
			[for="search"]{
				display: none;
			}

			[name="search"]{
				display: inline-block;
		    margin: 0;
		    width: 89%;
		    border: none;
		    height: 44px;
		    box-shadow: none;
			}

			[type="submit"]{
				@include transition-delay(1s);
				@include transition(opacity 0.3s linear);
        display: inline-block;
		    width: 10%;
		    border: none;
		    padding: 0;
		    background: transparent;
		    color: $black;
		    height: 44px;
		    opacity: 0;
			}
		}
	}

	&.active{
		.menu{
			position: relative;
			z-index: 2;

			li > a{
				&:before{
					display: none;
				}
			}
		}

		&:before{
			display: block;
		}

		.search-bar{
			left: 0;

			.sisea-search-form{
				[type="submit"]{
					opacity: 1;
				}
			}
		}		
	}
}

.search-results{
	h4{
		font-family: $source-sans-pro;
		@include font-x-large();
		font-weight: 600;

		.strong{
			font-weight: 700;
			font-style: italic;
		}
	}

	.media-object{
		h5{
			font-family: $source-sans-pro;
			@include font-large();
			font-weight: 600;
		}

		.extract{
			font-family: $source-sans-pro;
			.strong{
				font-weight: 700;
				font-style: italic;
			}
		}
	}
}
