@font-face {
  font-family: 'SourceSansPro';
  src: url('../fonts/SourceSansPro/SourceSansPro-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('../fonts/SourceSansPro/SourceSansPro-ExtraLightIt.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('../fonts/SourceSansPro/SourceSansPro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('../fonts/SourceSansPro/SourceSansPro-LightIt.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('../fonts/SourceSansPro/SourceSansPro-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('../fonts/SourceSansPro/SourceSansPro-It.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('../fonts/SourceSansPro/SourceSansPro-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('../fonts/SourceSansPro/SourceSansPro-SemiboldIt.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('../fonts/SourceSansPro/SourceSansPro-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('../fonts/SourceSansPro/SourceSansPro-BoldIt.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('../fonts/SourceSansPro/SourceSansPro-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('../fonts/SourceSansPro/SourceSansPro-BlackIt.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}


$source-sans-pro: 'SourceSansPro';

/*----------------------------------------------------------------------------------------
           FONT SIZE MIXINS
-----------------------------------------------------------------------------------------*/

@mixin font-x-small(){
  @include font-size(0.5);
}  
@mixin font-small(){
  @include font-size(0.75);
} 
@mixin font-medium(){
  @include font-size(1);
} 
@mixin font-x-medium(){
  @include font-size(1.25);
} 
@mixin font-large(){
  @include font-size(1.5);
} 
@mixin font-x-large(){
  @include font-size(2);
} 
@mixin font-xx-large(){
  @include font-size(2.5);
}
@mixin font-xxx-large(){
  @include font-size(3);
}