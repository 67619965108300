// @mixin transform($x, $y, $z){
//     -webkit-transform: translate3d($x, $y, $z);
//     -ms-transform: translate3d($x, $y, $z);
//     -o-transform: translate3d($x, $y, $z);
//     transform: translate3d($x, $y, $z);
// }

// @mixin transition($duration, $type, $easing){
//     -webkit-transition: $duration $type $easing;
//     -moz-transition: $duration $type $easing;
//     -o-transition: $duration $type $easing;
//     transition: $duration $type $easing;
// }
$base-blue-color: #34a2e7;
$base-blue-xlight: #e1e9eb;

$graceful-usage-threshold: 0.1;
$critical-usage-threshold: 0.01;
$has-layout-support-threshold: $critical-usage-threshold;
$use-legacy-gradient-syntax: true;

@function support-legacy-browser($browser, $min-version, $max-version: null, $threshold: $critical-usage-threshold) {
  @if not index($supported-browsers, $browser) {
    @return false;
  }
  // Check agaist usage stats and declared minimums
  $min-required-version: map-get($browser-minimum-versions, $browser);
  $usage: if($max-version, omitted-usage($browser, $min-version, $max-version), omitted-usage($browser, $min-version));
  @return $usage > $threshold or $min-required-version and compare-browser-versions($browser, $max-version or $min-version, $min-required-version) >= 0;
}

// @function linear-gradient($angle, $details...) {
//   $legacy-syntax: $use-legacy-gradient-syntax;
//   @if type-of($angle) != "number" {
//     $angle: compact($angle);
//     $legacy-syntax: if(index($angle, "to"), false, true);
//   }
//   @if $legacy-syntax {
//     @return _linear-gradient_legacy($angle, $details...);
//   }
//   @else {
//     @return _linear-gradient($angle, $details...);
//   }
// }

@mixin background-image($images...) {
  @include image-property(background-image, $images...) {
    @if $current-prefix == -svg {
      background-size: 100%;
    }
  }
}

@mixin has-layout-zoom {
  @if support-legacy-browser("ie", "7", $threshold: $has-layout-support-threshold) {
    *zoom: 1;
  }
}

@mixin has-layout-block {
  @if support-legacy-browser("ie", "7", $threshold: $has-layout-support-threshold) {
    // This makes ie6 get layout
    display: inline-block;
    // and this puts it back to block
    & {
      display: block;
    }
  }
}

@mixin has-layout($approach: $default-has-layout-approach) {
  @if support-legacy-browser("ie", "7", $threshold: $has-layout-support-threshold) {
    @if $approach == zoom {
      @include has-layout-zoom;
    }
    @else if $approach == block {
      @include has-layout-block;
    }
    @else {
      @warn "Unknown has-layout approach: #{$approach}";
      @include has-layout-zoom;
    }
  }
}

@mixin for-legacy-browsers($browsers, $threshold: $critical-usage-threshold) {
  $rendered: false;
  @each $browser, $range in $browsers {
    @if not $rendered {
      @include for-legacy-browser($browser, $range..., $threshold: $threshold, $ranges: $browsers) {
        $rendered: true;
        @content;
      }
    }
  }
}

@mixin dropshadow($arguments...){
  -webkit-box-shadow: $arguments;
  -moz-box-shadow: $arguments;
  box-shadow: $arguments;
}

@mixin border-radius($radius: 5px){
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin background-size($cover: cover){
  -webkit-background-size: $cover;
  -moz-background-size: $cover;
  -o-background-size: $cover;
  background-size: $cover;
}

$rem-base: 16px !default;

// STRIP UNIT
// It strips the unit of measure and returns it
@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

// CONVERT TO REM
@function convert-to-rem($value, $base-value: $rem-base)  {
  $value: strip-unit($value) / strip-unit($base-value) * 1rem;
  @if ($value == 0rem) { $value: 0; } // Turn 0rem into 0
  @return $value;
}


@function rem-calc($values, $base-value: $rem-base) {
  $max: length($values);

  @if $max == 1 { @return convert-to-rem(nth($values, 1), $base-value); }

  $remValues: ();
  @for $i from 1 through $max {
    $remValues: append($remValues, convert-to-rem(nth($values, $i), $base-value));
  }
  @return
   $remValues;
}


//============================================================
// Typography
//============================================================

// An rem font-size mixin providing fallback to px
@mixin font-size($sizeValue: 1.4) {
    $remValue: $sizeValue;
    $pxValue: ($sizeValue * 16);
    font-size: #{$pxValue}px;
    font-size: #{$remValue}rem;
}

// Requires inline-block or block for proper styling
@mixin text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

//============================================================
// Transforms
//============================================================

@mixin transform($arguments...) {
    -webkit-transform: $arguments;
      -ms-transform: $arguments; // IE9
          transform: $arguments;
}

@mixin transform-origin($arguments...) {
  -webkit-transform-origin: $arguments;
      -ms-transform-origin: $arguments; // IE9
          transform-origin: $arguments;
}

@mixin rotate($arguments...) {
  -webkit-transform: rotate($arguments);
      -ms-transform: rotate($arguments); // IE9
          transform: rotate($arguments);
}

@mixin backface-visibility ($arguments) {
  -webkit-backface-visibility: $arguments;
     -moz-backface-visibility: $arguments;
      -ms-backface-visibility: $arguments;
       -o-backface-visibility: $arguments;
          backface-visibility: $arguments;
}


//============================================================
// Animations
//============================================================

@mixin transition($arguments...) {
    -webkit-transition: $arguments;
            transition: $arguments;
}

@mixin transition-delay($arguments...) {
    -webkit-transition-delay: $arguments;
            transition-delay: $arguments;
}

@mixin animation($arguments) {
  -webkit-animation: $arguments;
          animation: $arguments;
}

//============================================================
// Gradients
//============================================================

@mixin linear-gradient ($from, $to) {
  background-color: $from;
  background-image: -webkit-linear-gradient(top, $from, $to); /* Chrome 10-25, iOS 5+, Safari 5.1+ */
  background-image:         linear-gradient(to bottom, $from, $to); /* Chrome 26, Firefox 16+, IE 10+, Opera 12.10+ */
}

@mixin filter-gradient($start-color, $end-color, $orientation: vertical) {
  @include for-legacy-browsers((ie: "8"), $gradient-support-threshold) {
    @include has-layout;
    $gradient-type: if($orientation == vertical, 0, 1);
    filter: progid:DXImageTransform.Microsoft.gradient(gradientType=#{$gradient-type}, startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}');
  }
}

//============================================================
// Structure
//============================================================

@mixin box-sizing ($arguments) {
  -webkit-box-sizing: $arguments;
     -moz-box-sizing: $arguments;
       -o-box-sizing: $arguments;
          box-sizing: $arguments;
}


//============================================================
//
// Easing
//
// Thanks to Robert Penner for his sterling work on easing,
// and to Matthew Lein for converting these functions into
// approximated cubic-bezier functions. Respect.
//
// @see http://robertpenner.com/
// @see http://matthewlein.com/ceaser/
//
//============================================================

// Cubic
$easeInCubic     :  cubic-bezier(0.550, 0.055, 0.675, 0.190);
$easeOutCubic    :  cubic-bezier(0.215, 0.610, 0.355, 1.000);
$easeInOutCubic  :  cubic-bezier(0.645, 0.045, 0.355, 1.000);

// Circ
$easeInCirc      :  cubic-bezier(0.600, 0.040, 0.980, 0.335);
$easeOutCirc     :  cubic-bezier(0.075, 0.820, 0.165, 1.000);
$easeInOutCirc   :  cubic-bezier(0.785, 0.135, 0.150, 0.860);

// Expo
$easeInExpo      :  cubic-bezier(0.950, 0.050, 0.795, 0.035);
$easeOutExpo     :  cubic-bezier(0.190, 1.000, 0.220, 1.000);
$easeInOutExpo   :  cubic-bezier(1.000, 0.000, 0.000, 1.000);

// Quad
$easeInQuad      :  cubic-bezier(0.550, 0.085, 0.680, 0.530);
$easeOutQuad     :  cubic-bezier(0.250, 0.460, 0.450, 0.940);
$easeInOutQuad   :  cubic-bezier(0.455, 0.030, 0.515, 0.955);

// Quart
$easeInQuart     :  cubic-bezier(0.895, 0.030, 0.685, 0.220);
$easeOutQuart    :  cubic-bezier(0.165, 0.840, 0.440, 1.000);
$easeInOutQuart  :  cubic-bezier(0.770, 0.000, 0.175, 1.000);

// Quint
$easeInQuint     :  cubic-bezier(0.755, 0.050, 0.855, 0.060);
$easeOutQuint    :  cubic-bezier(0.230, 1.000, 0.320, 1.000);
$easeInOutQuint  :  cubic-bezier(0.860, 0.000, 0.070, 1.000);

// Sine
$easeInSine      :  cubic-bezier(0.470, 0.000, 0.745, 0.715);
$easeOutSine     :  cubic-bezier(0.390, 0.575, 0.565, 1.000);
$easeInOutSine   :  cubic-bezier(0.445, 0.050, 0.550, 0.950);

// Back
$easeInBack      :  cubic-bezier(0.600, -0.280, 0.735, 0.045);
$easeOutBack     :  cubic-bezier(0.175,  0.885, 0.320, 1.275);
$easeInOutBack   :  cubic-bezier(0.680, -0.550, 0.265, 1.550);