@media only screen and (min-width: 40em) and (max-width: 64em) { 
  

  @media(orientation: landscape){
      
  }

  @media (-webkit-min-device-pixel-ratio: 2) {
    

    @media(orientation: landscape){

    }
  }

  /*iPad:*/
  @media (device-aspect-ratio: 3/4) {}
}


